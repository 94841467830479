// TODO: This should be refactored and removed (and created by python)

const pagesAndCategories = {
  "/viktmaskiner/multigym/": ["multigym", "multigym & hemgym"],
  "/viktmaskiner/chinsstang/": [
    "hävräck",
    "hävräcken",
    "chinsstång",
    "chinsstänger",
  ],
  "/viktmaskiner/crosstrainer/": [
    "crosstrainer",
    "crosstrainers",
    "crosstrainer till företag",
    "pro crosstrainer",
  ],
  "/fria-vikter/kettlebell/": ["kettlebells"],
  "/fria-vikter/medicinboll/": [
    "medicinboll",
    "medicinbollar",
    "medicinbollar / wall / slamballs",
    "pro slamball | wallball | medicinboll",
    "slamballs",
  ],
  "/tillbehor/gymmatta/": [
    "golv",
    "golv/matta",
    "gymgolv",
    "gymgolv & plattformar",
    "gymmatta",
    "skyddsmatta",
    "skyddsmattor",
    "skyddsmattor och tillbehör",
  ],
  "/tillbehor/styrkelyftsbalte/": [
    "bälten",
    "bälte",
    "träningsbälten",
    "träningsbälte",
  ],
  "/tillbehor/traningshandskar/": [
    "träningshandske",
    "träningshandskar",
    "gymhandske",
    "gymhandskar",
    "gym handske",
    "gym handskar",
    "tränings handske",
    "tränings handskar",
    "träningshandskar och handstöd",
    "workout gloves",
    "gym gloves",
    "wrap gloves",
    "styrketräningshandskar",
    "training gloves",
    "TrÃ¤ningshandskar",
  ],
  "/fria-vikter/traningsband/": [
    "elastiskt band",
    "elastiska band",
    "motståndsband",
    "gymband",
    "gummiband",
    "träningsband",
    "Gummiband & Träningsband",
    "träningsband & gummiband",
  ],
  "/fria-vikter/hantlar/": ["hantlar", "hantel", "dumbell"],
  "/fria-vikter/skivstanger/": ["skivstång", "skivstänger", "barbell"],
  "/kondition/lopband/": ["löpband", "gåband", "joggingband", "treadmill"],
  "/kondition/spinningcyklar/": [
    "spinningcykel",
    "spinningcyklar till företag",
    "spinningcyklar",
    "spinning cykel",
    "spinning bike",
  ],
  "/kondition/trappmaskiner/": ["trappmaskin", "trapp maskin", "stair machine"],
  "/viktmaskiner/traningsbank/": ["träningsbänkar", "träningsbänk"],
  "/viktmaskiner/kabelmaskiner/": [
    "cable cross",
    "cablecross",
    "kryssdrag",
    "kabelmaskin",
    "kabelmaskiner",
    "cable",
  ],
}

module.exports = { pagesAndCategories: pagesAndCategories }
