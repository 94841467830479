import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./main.css"

const BrandList = ({ data, location }) => {
  const queryData = useStaticQuery(graphql`
    query MyQuery {
      allMongodbStoreProducts {
        edges {
          node {
            brand
            fields {
              brand_url
            }
          }
        }
      }
    }
  `)

  var allBrands = queryData.allMongodbStoreProducts.edges
  var uniqueBrandsAndUrls = []
  var brandsAdded = []

  var arrayLength = allBrands.length
  for (var i = 0; i < arrayLength; i++) {
    var brand = allBrands[i].node.brand
    if (!brandsAdded.includes(brand)) {
      brandsAdded.push(brand)
      uniqueBrandsAndUrls.push([brand, allBrands[i].node.fields.brand_url])
    }
  }

  uniqueBrandsAndUrls.sort(
    (function (index) {
      return function (a, b) {
        return a[index] === b[index] ? 0 : a[index] < b[index] ? -1 : 1
      }
    })(0)
  )

  return (
    <div id="brandWrapper">
      <ul>
        {uniqueBrandsAndUrls.map(function (brand, index) {
          return (
            <li key={index}>
              <Link to={"/produkter/" + brand[1] + "/"}>{brand[0]}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default BrandList
