import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./main.css"
import { pagesAndCategories } from "../utils/constants.js"

function makeStringUrlFriendly(value) {
  var newValue = value
  newValue = newValue.replace(/[åä]+/gi, "a")
  newValue = newValue.replace(/[ÅÄ]+/gi, "A")
  newValue = newValue.replace(/[ö]+/gi, "o")
  newValue = newValue.replace(/[Ö]+/gi, "O")
  return encodeURIComponent(
    newValue.toLowerCase().replace(/[^a-z0-9_-]+/gi, "-")
  )
}

const CategoryList = ({ data, location }) => {
  const queryData = useStaticQuery(graphql`
    query CategoryQuery {
      allMongodbStoreProducts {
        edges {
          node {
            categories
          }
        }
      }
    }
  `)

  var all_categories = []
  var categories_urls = {}
  queryData.allMongodbStoreProducts.edges.forEach(node => {
    node.node.categories.forEach(category => {
      if (!all_categories.includes(category)) {
        all_categories.push(category)
        categories_urls[category] =
          "/produkter/" + makeStringUrlFriendly(category) + "/"
      }
    })
  })
  all_categories.sort()

  for (const [page, categories] of Object.entries(pagesAndCategories)) {
    categories.forEach(function (category, index) {
      categories_urls[category] = page
    })
  }

  return (
    <div id="categoryWrapper">
      <ul>
        {all_categories.map(function (category, index) {
          return (
            <li key={index}>
              <Link to={categories_urls[category]}>{category}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default CategoryList
