import { graphql, Link } from "gatsby";
import BrandList from "../../../../src/components/brandList.js";
import CategoryList from "../../../../src/components/categoryList.js";
import * as React from 'react';
export default {
  graphql,
  Link,
  BrandList,
  CategoryList,
  React
};